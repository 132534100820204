import React from "react";
import Image from "next/image";
import dynamic from "next/dynamic";
import Skeleton from "react-loading-skeleton";
import http from "api";
import { MAIN } from "common/constants/pathRoutes";
import { getRandomItems, getTransformedLink, IMAGE_BLUR_PLACEHOLDER } from "common/utils";
import BlogPostCardShimmer from "components/cards/BlogPostCard/shimmer";
import RecommendationCard from "components/cards/RecommendationCard";
import BookPreviewCard from "components/cards/BookPreviewCard";
import Slider from "components/Slider";
import { recommendationConfig, opportunitiesConfig, stepsDataConfig, stepsSeparatorConfig } from "./config";
import * as S from "../MainPage.styled";
const BlogPostCard = dynamic(() => import("components/cards/BlogPostCard"), {
  ssr: false,
  loading: () => <BlogPostCardShimmer />
});
export const getOrderedCategories = (data = []) => {
  return [...data].sort((a, b) => b.count - a.count);
};
export const getBookCards = data => {
  return data.map(({
    book,
    buyLink,
    sellLink,
    price
  }, index) => <BookPreviewCard key={index} book={book} price={price} urls={{
    sell: sellLink,
    buy: buyLink
  }} data-test-id="MainPage-BookPreviewCard" />);
};
const getBlogImgPath = post => post._embedded?.["wp:featuredmedia"]?.[0]?.media_details?.sizes?.medium?.source_url || post._embedded?.["wp:featuredmedia"]?.[0]?.source_url;
export const getBlogPostCards = data => {
  return data.map((post, key) => {
    const title = post.title.rendered;
    const url = getTransformedLink(post.link);
    const imgPath = getBlogImgPath(post);
    return <BlogPostCard key={key} title={title} imgPath={imgPath} url={url} data-test-id="MainPage-BlogPostCard" />;
  });
};
export const getCategoriesLinks = data => {
  return data.map(({
    name,
    count,
    link
  }, key) => {
    const url = getTransformedLink(link);
    return <S.CategoryLink key={key} href={url} data-test-id="MainPage-CategoryLink" dangerouslySetInnerHTML={{
      __html: `${name} (${count})`
    }} />;
  });
};
export const getShimmeredCategories = () => {
  return [...new Array(10)].map((item, index) => <S.ShimmeredCategory key={index}>
            <Skeleton />
        </S.ShimmeredCategory>);
};
const getRecommendationCards = path => recommendationConfig[path].map(({
  title,
  content,
  imgPath,
  backgroundColor
}, key) => {
  return <RecommendationCard key={key} title={title} content={content} imgPath={imgPath} backgroundColor={backgroundColor} data-test-id="MainPage-RecommendationCard" />;
});
export const recommendationCardsConfig = Object.keys(recommendationConfig).reduce((acc, curr) => ({
  ...acc,
  [curr]: getRecommendationCards(curr)
}), {
  default: getRecommendationCards(MAIN)
});
const getOpportunitiesItems = pathname => opportunitiesConfig[pathname].map(({
  image,
  content,
  alt
}, key) => {
  return <S.OpportunitiesItemWrapper key={key}>
                <S.OpportunitiesImageWrapper>
                    <Image src={image} alt={alt} aria-label={alt} blurDataURL={IMAGE_BLUR_PLACEHOLDER} placeholder="blur" layout="fill" objectFit="fill" loading="lazy" />
                </S.OpportunitiesImageWrapper>
                <S.OpportunitiesTextWrapper>{content}</S.OpportunitiesTextWrapper>
            </S.OpportunitiesItemWrapper>;
});
export const opportunitiesCardsConfig = Object.keys(opportunitiesConfig).reduce((acc, curr) => ({
  ...acc,
  [curr]: getOpportunitiesItems(curr)
}), {
  default: getOpportunitiesItems(MAIN)
});
const getStepsList = routePath => {
  const stepData = stepsDataConfig[routePath];
  return <S.StepsItemsWrapper>
            {Object.keys(stepData).map((title, id) => <React.Fragment key={id}>
                    <S.StepWrapper data-test-id="MainPage-StepWrapper">
                        <S.StepTitle data-test-id="MainPage-StepTitle">
                            <S.StepIdHelper data-test-id="MainPage-StepId">{id + 1}</S.StepIdHelper>
                            {" . "}
                            {title}
                        </S.StepTitle>
                        <S.StepText data-test-id="MainPage-StepText">{stepData[title]}</S.StepText>
                    </S.StepWrapper>
                    {stepsSeparatorConfig[id + 1] && <S.StepLineSeparator />}
                </React.Fragment>)}
        </S.StepsItemsWrapper>;
};
export const stepListConfig = Object.keys(stepsDataConfig).reduce((acc, curr) => ({
  ...acc,
  [curr]: getStepsList(curr)
}), {
  default: getStepsList(MAIN)
});
export const renderBooksContent = books => {
  return <S.CardsContainer data-test-id="MainPage-CardsContainer">
            {getBookCards(books)}
        </S.CardsContainer>;
};
export const renderEmptyContent = content => {
  return <S.NoDataTitle data-test-id="MainPage-NoDataTitle">{content}</S.NoDataTitle>;
};
export const getBooksContent = ({
  books = [],
  isBooksLoading
}) => books.length ? renderBooksContent(books) : renderEmptyContent(isBooksLoading ? "Loading..." : "No Recently Searched High Resale Value Books loaded...");
export const renderPostsContent = cards => {
  return <>
            <S.SliderWrapper>
                <Slider>{cards}</Slider>
            </S.SliderWrapper>
            <S.BlogPostCards data-test-id="MainPage-BlogPostCards">{cards}</S.BlogPostCards>
        </>;
};
export const getShimmeredPosts = () => {
  return [...new Array(3)].map((item, index) => <BlogPostCardShimmer key={index} />);
};
export const getSuggestedBooks = async () => {
  const [recentBooks, highResaleBooks] = await Promise.all([http.getBooksData(), http.getHighResaleBooks()]);
  return [...getRandomItems(recentBooks, 2), ...getRandomItems(highResaleBooks, 1)];
};