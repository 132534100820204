import Main from "modules/Main";
import { prefetchMainData } from "modules/Main/actions";

export const getServerSideProps = async () => {
    const prefetchedData = await prefetchMainData();

    return prefetchedData;
};

export default Main;
