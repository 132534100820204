import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import { IMAGE_BLUR_PLACEHOLDER } from "common/utils";
import * as S from "./VideoPlayer.styled";
const VideoPlayer = ({
  name,
  embedId,
  styles,
  ...restProps
}) => {
  const [isEnabled, setEnableState] = useState(false);
  const handleClick = () => !isEnabled && setEnableState(true);
  return <S.VideoContainer style={styles} {...!isEnabled && {
    onClick: handleClick,
    tabIndex: 0,
    onKeyDown: handleClick,
    "aria-label": "Play video",
    role: "button"
  }} {...restProps}>
            {isEnabled ? <S.IframeContent title={name} src={`https://www.youtube.com/embed/${embedId}?autoplay=1`} frameBorder="0" allowFullScreen allow="autoplay; encrypted-media" /> : <Image src={`https://i.ytimg.com/vi/${embedId}/maxresdefault.jpg`} alt={name} layout="fill" loading="lazy" placeholder={"blur"} blurDataURL={IMAGE_BLUR_PLACEHOLDER} />}
            <S.VideoButton aria-label="Play video" isEnabled={isEnabled}>
                <S.Svg width="68" height="48" viewBox="0 0 68 48">
                    {/* eslint-disable-next-line max-len */}
                    <S.SvgShapePath d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" />
                    <S.SvgIconPath d="M 45,24 27,14 27,34" />
                </S.Svg>
            </S.VideoButton>
        </S.VideoContainer>;
};
VideoPlayer.propTypes = {
  embedId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  styles: PropTypes.object
};
VideoPlayer.defaultProps = {
  styles: {}
};
export default memo(VideoPlayer);