import React from "react";

export const PAGE_TITLE = "Vendors/Bookstores List";

export const getVendorsQueryParams = (type) => ({
    buySell: type
});

export const getPageContent = () => (
    <>
        <p>
            BookScouter compares prices across more than 30 vendors and bookstores to help you find
            the best place to sell textbooks, as well as rent and buy books. Search for the book you
            want to buy, sell, or rent to see all book-selling websites that have it on offer.
        </p>
        <p>
            Here is a list of all book buyers and sellers whose prices you can check at BookScouter.
            Click on the icons above to find out more about specific book vendors and online
            bookstores. Each profile includes the following information:
        </p>
        <ul>
            <li>description and contact details</li>
            <li>payment methods</li>
            <li>shipping features</li>
            <li>rating and feedback</li>
        </ul>

        <p>
            The ratings and reviews of vendors and bookstores, given by BookScouter users over the
            years, will help you determine the right place for buying, renting, and selling books.
            If you have used some of these vendors to buy used college books or sell textbooks, take
            a minute and leave your feedback! It will help our community find the best place to sell
            textbooks.
        </p>

        <p>
            <strong>Preferred book vendors for selling books</strong>
            <br />
            Have a look at the vendors to whom you can sell used books. BookScouter has an
            established relationship with these companies because they have provided the best book
            selling experience for our customers.
        </p>
        <p>
            <strong>Normal vendors</strong>
            <br />
            These are further vendors offering book buybacks with whom we cooperate.
        </p>
        <p>
            <strong>Online stores for buying and renting used books</strong>
            <br />
            BookScouter helps you check prices from these online stores where you can buy or rent
            books.
        </p>
    </>
);
