import React, { memo, useCallback, useEffect, useState } from "react";
import Image from "next/image";
import PropTypes from "prop-types";
import { IMAGE_BLUR_PLACEHOLDER } from "common/utils";
import { BOOK_UNAVAILABLE_IMG } from "./constants";
import * as S from "./BookAsset.styled";
const BookAsset = ({
  book,
  wrapper: WrapperComponent,
  imageProps,
  showPreview,
  objectFit
}) => {
  const [imageSrc, setImageSrc] = useState(IMAGE_BLUR_PLACEHOLDER);
  const handleOnError = useCallback(() => {
    setImageSrc(BOOK_UNAVAILABLE_IMG);
  }, [book]);

  /** need to bypass case with react hydration
   *  in https://github.com/facebook/react/issues/15446#issuecomment-484699248
   */
  useEffect(() => {
    setImageSrc(showPreview ? book.previewImg : book.img);
  }, [book, showPreview]);
  return <WrapperComponent>
            <Image src={imageSrc} alt={`${book.title} image`} aria-label={`${book.title} image`} blurDataURL={IMAGE_BLUR_PLACEHOLDER} placeholder={"blur"} layout="fill" objectFit={objectFit} priority {...imageProps} onError={handleOnError} />
        </WrapperComponent>;
};
BookAsset.propTypes = {
  book: PropTypes.object.isRequired,
  wrapper: PropTypes.elementType,
  imageProps: PropTypes.object,
  showPreview: PropTypes.bool,
  objectFit: PropTypes.string.isRequired
};
BookAsset.defaultProps = {
  wrapper: S.BookAssetWrapper,
  imageProps: {},
  showPreview: false,
  objectFit: "contain"
};
export default memo(BookAsset);