import React, { memo } from "react";
import Skeleton from "react-loading-skeleton";
import * as S from "./BlogPostCardShimmer.styled";
const BlogPostCardShimmer = () => {
  return <S.Container>
            <S.Preview>
                <Skeleton />
            </S.Preview>
            <S.Title>
                <Skeleton />
                <Skeleton />
            </S.Title>
        </S.Container>;
};
export default memo(BlogPostCardShimmer);