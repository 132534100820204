import http from "api";

import { addErrorNotification } from "common/notifications";

import {
    SUCCESS_FETCH_CATEGORIES,
    SUCCESS_FETCH_POSTS,
    ERROR_FETCH_CATEGORIES,
    ERROR_FETCH_POSTS,
    SET_ALL_VENDORS
} from "../reducers/actionTypes";

import { getSuggestedBooks } from "../utils";
import { getVendorsQueryParams } from "../../Vendors/subModules/VendorsListPage/utils";

const POST_QUERY_PARAMS = {
    _embed: true,
    per_page: 3
};

const CATEGORIES_QUERY_PARAMS = {
    _embed: true,
    per_page: 100
};

export const fetchPostsData = async ({ dispatch }) => {
    try {
        const response = await http.getPostsData({ params: POST_QUERY_PARAMS });

        dispatch({ type: SUCCESS_FETCH_POSTS, payload: response });
    } catch (error) {
        dispatch({ type: ERROR_FETCH_POSTS });

        console.error(error);

        addErrorNotification({ msg: error.message });
    }
};

export const fetchCategoriesData = async ({ dispatch }) => {
    try {
        const response = await http.getCategoriesData({
            params: CATEGORIES_QUERY_PARAMS
        });

        dispatch({ type: SUCCESS_FETCH_CATEGORIES, payload: response });
    } catch (error) {
        dispatch({ type: ERROR_FETCH_CATEGORIES });

        console.error(error);

        addErrorNotification({ msg: error.message });
    }
};

export const fetchAllVendors = async (dispatch, type) => {
    try {
        const unsortedVendors = await http.getVendors({
            params: getVendorsQueryParams(type),
            allVendors: true
        });

        const allVendors = unsortedVendors.reduce((acc, vendor) => {
            if (acc.includes(vendor.slug)) {
                return acc;
            }

            return [...acc, vendor];
        }, []);

        dispatch({
            type: SET_ALL_VENDORS,
            payload: allVendors
        });
    } catch (err) {
        console.error(err);
    }
};

export const prefetchMainData = async () => {
    try {
        const books = await getSuggestedBooks();

        return {
            props: {
                prefetchedData: {
                    books
                }
            }
        };
    } catch (error) {
        console.error(error);
    }

    return {
        props: { prefetchedData: { books: [] } }
    };
};
