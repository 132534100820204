import React, { memo } from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import { IMAGE_BLUR_PLACEHOLDER } from "common/utils";
import * as S from "./RecommendationCard.styled";
const RecommendationCard = ({
  title,
  content,
  imgPath,
  backgroundColor
}) => <S.RecommendationContainer backgroundColor={backgroundColor}>
        <S.RecommendationPreview>
            <Image src={imgPath} alt="Recommendation card image" aria-label={`${title} image`} blurDataURL={IMAGE_BLUR_PLACEHOLDER} placeholder="blur" layout="fill" objectFit="contain" loading="lazy" />
        </S.RecommendationPreview>
        <S.RecommendationTitle>{title}</S.RecommendationTitle>
        <S.RecommendationContent>{content}</S.RecommendationContent>
    </S.RecommendationContainer>;
RecommendationCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  imgPath: PropTypes.string,
  backgroundColor: PropTypes.string,
  styles: PropTypes.object
};
RecommendationCard.defaultProps = {
  title: "Some title",
  content: <p>Some content</p>,
  imgPath: "",
  backgroundColor: "#eaefeb",
  styles: {}
};
export default memo(RecommendationCard);