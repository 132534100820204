import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { getRandomVendors } from "./utils";
import { DATA_CONFIG } from "./constants";
import VendorAsset from "components/VendorAsset";
import { VENDOR_LOGO, VENDOR_CUSTOM_LOGO } from "components/VendorAsset/constants";
import { SELL_STATE } from "common/constants/searchTypes";
import * as S from "./BubblestBanner.styled";
const BubblesBanner = ({
  vendors,
  pageType
}) => {
  const type = pageType === "" ? SELL_STATE : pageType;
  const randomVendorsSet = useMemo(() => getRandomVendors(vendors, type), [vendors]);
  return <S.ChartContainer>
            {randomVendorsSet.map((vendor, index) => <S.BubbleItem key={`bubble-${index}`} x={DATA_CONFIG[index].x} y={DATA_CONFIG[index].y} r={DATA_CONFIG[index].r} xTablet={DATA_CONFIG[index].xTablet} yTablet={DATA_CONFIG[index].yTablet} rTablet={DATA_CONFIG[index].rTablet}>
                    <VendorAsset vendor={vendor} wrapper={S.BubbleItemWrapper} type={vendor.customLogo ? VENDOR_CUSTOM_LOGO : VENDOR_LOGO} imageProps={{
        blurDataURL: undefined,
        placeholder: undefined
      }} />
                </S.BubbleItem>)}
        </S.ChartContainer>;
};
BubblesBanner.propTypes = {
  vendors: PropTypes.array,
  pageType: PropTypes.string
};
BubblesBanner.defaultProps = {
  vendors: []
};
export default BubblesBanner;