import React from "react";

import { MAIN, BUY, RENT } from "common/constants/pathRoutes";

import highestPriceIcon from "public/images/main/highest-price.svg";
import priceAlertsIcon from "public/images/main/price-alerts.svg";
import singleSearchIcon from "public/images/main/single-search.svg";
import vendorsStoreIcon from "public/images/main/vendors-store.svg";

export const tabsContentConfig = {
    [MAIN]: {
        pageTitle: "Buy and sell your books",
        action: "sell"
    },
    [BUY]: {
        pageTitle: "Buy your books",
        action: "buy"
    },
    [RENT]: {
        pageTitle: "Rent your books",
        action: "rent"
    }
};

export const opportunitiesConfig = {
    [MAIN]: [
        {
            image: vendorsStoreIcon,
            alt: "Vendors store",
            content: (
                <p>
                    <b>30+ vendors</b> compete to buy your books
                </p>
            )
        },
        {
            image: singleSearchIcon,
            alt: "Single search",
            content: (
                <p>
                    Compare offers with a <b>single search</b>
                </p>
            )
        },
        {
            image: highestPriceIcon,
            alt: "Highest price",
            content: (
                <p>
                    Find <b>the highest</b> buyback price
                </p>
            )
        },
        {
            image: priceAlertsIcon,
            alt: "Track price",
            content: (
                <p>
                    Track price changes, get <b>price alerts</b>
                </p>
            )
        }
    ],
    [BUY]: [
        {
            image: vendorsStoreIcon,
            content: (
                <p>
                    <b>25+ bookstores</b> compete for your order
                </p>
            )
        },
        {
            image: singleSearchIcon,
            content: (
                <p>
                    Compare offers with a <b>single search</b>
                </p>
            )
        },
        {
            image: highestPriceIcon,
            content: (
                <p>
                    Find <b>the best deal</b> instantly
                </p>
            )
        },
        {
            image: priceAlertsIcon,
            content: (
                <p>
                    <b>Save</b> up to 70% on textbooks
                </p>
            )
        }
    ],
    [RENT]: [
        {
            image: vendorsStoreIcon,
            content: (
                <p>
                    <b>20+ vendors</b> compete for your order
                </p>
            )
        },
        {
            image: singleSearchIcon,
            content: (
                <p>
                    Compare offers with a <b>single search</b>
                </p>
            )
        },
        {
            image: highestPriceIcon,
            content: (
                <p>
                    Find <b>the best deal</b> instantly
                </p>
            )
        },
        {
            image: priceAlertsIcon,
            content: (
                <p>
                    <b>Save</b> up to 70% on textbooks
                </p>
            )
        }
    ]
};

export const recommendationDescriptionConfig = {
    [MAIN]: (
        <>
            We pride ourselves in being the world&lsquo;s largest textbook buyback price comparison
            tool. In addition to helping you get rid of your old textbooks, we also offer:
        </>
    ),
    [BUY]: (
        <>
            We pride ourselves in being one the world&lsquo;s largest textbooks and used books price
            comparison tools. In addition to helping you buy cheap college textbooks online, we also
            offer:
        </>
    ),
    [RENT]: (
        <>
            We pride ourselves in being one the world&lsquo;s largest textbooks and used books price
            comparison tools. In addition to helping you sell, buy & rent textbooks online, we also
            offer:
        </>
    )
};

export const recommendationConfig = {
    [MAIN]: [
        {
            title: "Best Price Recommendations",
            content: (
                <p>
                    With one simple search we connect you to various companies buying used textbooks
                    online. By comparing textbook buyback prices, we ensure you receive the best
                    prices for your textbooks. Shipping is free and you&apos;re often paid the same
                    day your book is received.
                </p>
            ),
            imgPath: "/images/main/textbooks-box-graphic.svg",
            backgroundColor: "#eaefeb"
        },
        {
            title: "Vendor Ratings & Reviews",
            content: (
                <p>
                    After you&apos;ve searched your ISBN, we display a list of companies with offers
                    for your book. BookScouter users can rate and review each vendor with our
                    feedback system. We display this rating next to each vendor so you can sell your
                    books with confidence.
                </p>
            ),
            imgPath: "/images/main/resources-box-graphic.svg",
            backgroundColor: "#f2f6e9"
        },
        {
            title: "Other Buyback Resources",
            content: (
                <p>
                    {"Have questions about "}
                    <a
                        target="_blank"
                        href="/blog/2021/11/top-5-best-websites-to-sell-books-in-2021"
                        rel="noreferrer">
                        selling books
                    </a>
                    {" online? Can't find the "}
                    <a target="_blank" href="/tips/whats-an-isbn" rel="noreferrer">
                        ISBN
                    </a>
                    {"? Will your book be accepted in its current "}
                    <a
                        target="_blank"
                        href="/tips/determining-a-books-condition-before-reselling-it"
                        rel="noreferrer">
                        condition
                    </a>
                    {"? Is your book "}
                    <a target="_blank" href="/tips/counterfeit-books" rel="noreferrer">
                        counterfeit
                    </a>
                    {"? We have many "}
                    <a target="_blank" href="/tips" rel="noreferrer">
                        tips
                    </a>
                    {" available whether this is your first time" +
                        "selling books, or if you have your own "}
                    <a
                        target="_blank"
                        href="/blog/2020/04/best-resources-for-reselling-books"
                        rel="noreferrer">
                        book buying
                    </a>
                    {" business."}
                </p>
            ),
            imgPath: "/images/main/ratings-box-graphic.svg",
            backgroundColor: "#fdecdc"
        }
    ],
    [BUY]: [
        {
            title: "Help You Find the Best Deal",
            content: (
                <p>
                    With one simple search we connect you to various companies selling new and used
                    college textbooks online. By comparing textbook prices, we ensure you receive
                    the best deal for your college books instantly.
                </p>
            ),
            imgPath: "/images/main/textbooks-box-graphic.svg",
            backgroundColor: "#eaefeb"
        },
        {
            title: "Seller Ratings & Reviews",
            content: (
                <p>
                    BookScouter users can rate and review each seller with our feedback system. We
                    display this rating next to each online bookseller so you can buy your textbooks
                    with confidence.
                </p>
            ),
            imgPath: "/images/main/resources-box-graphic.svg",
            backgroundColor: "#f2f6e9"
        },
        {
            title: "College Life & Study Tips",
            content: (
                <p>
                    {"Have questions about "}
                    <a
                        target="_blank"
                        href="/blog/2021/11/10-questions-answers-about-access-codes-for-textbooks"
                        rel="noreferrer">
                        access codes
                    </a>
                    {"? Interested in where to find "}
                    <a target="_blank" href="/blog/2021/12/free-textbooks-online" rel="noreferrer">
                        free textbooks
                    </a>
                    {" online? Have questions about "}
                    <a
                        target="_blank"
                        href="/blog/2021/11/slader-vs-chegg-study-vs-course-hero-textbook-solutions"
                        rel="noreferrer">
                        textbook solutions
                    </a>
                    {" or "}
                    <a
                        target="_blank"
                        href="/blog/2021/11/25-websites-to-get-help-with-your-homework"
                        rel="noreferrer">
                        homework help
                    </a>
                    {" sites? We have many helpful "}
                    <a target="_blank" href="/blog" rel="noreferrer">
                        posts
                    </a>
                    {" available whether this is your first" +
                        "year in college, or you are about to graduate."}
                </p>
            ),
            imgPath: "/images/main/ratings-box-graphic.svg",
            backgroundColor: "#fdecdc"
        }
    ],
    [RENT]: [
        {
            title: "Help You Find the Best Deal",
            content: (
                <p>
                    With one simple search we connect you to various textbook rental vendors
                    offering new and used college books for rent. By comparing textbook rental
                    prices, we ensure you receive the best deal for your college books instantly.
                </p>
            ),
            imgPath: "/images/main/textbooks-box-graphic.svg",
            backgroundColor: "#eaefeb"
        },
        {
            title: "Seller Ratings & Reviews",
            content: (
                <p>
                    BookScouter users can rate and review each vendor with our feedback system. We
                    display this rating next to each textbook rental website so you can rent your
                    college books with confidence.
                </p>
            ),
            imgPath: "/images/main/resources-box-graphic.svg",
            backgroundColor: "#f2f6e9"
        },
        {
            title: "College Life & Study Tips",
            content: (
                <p>
                    {"Have questions about where to "}
                    <a target="_blank" href="/tips/where-to-recycle-textbooks" rel="noreferrer">
                        recycle textbooks
                    </a>
                    {"? Not sure about "}
                    <a
                        target="_blank"
                        href="/blog/2019/10/how-to-know-when-its-time-to-drop-out-of-college"
                        rel="noreferrer">
                        dropping out
                    </a>
                    {" of college or best "}
                    <a
                        target="_blank"
                        href="/blog/2021/11/top-5-best-websites-to-sell-books-in-2021"
                        rel="noreferrer">
                        websites
                    </a>
                    {" to sell books? Looking for ideas on how to "}
                    <a
                        target="_blank"
                        href="/blog/2021/03/8-ways-to-make-money-online-as-a-side-hustle"
                        rel="noreferrer">
                        make money
                    </a>
                    {" online, or where to find a perfect "}
                    <a
                        target="_blank"
                        href="/blog/2020/04/online-jobs-for-college-students"
                        rel="noreferrer">
                        job
                    </a>
                    {" for a college student? BookScouter is here to "}
                    <a target="_blank" href="/blog" rel="noreferrer">
                        help
                    </a>
                    {"."}
                </p>
            ),
            imgPath: "/images/main/ratings-box-graphic.svg",
            backgroundColor: "#fdecdc"
        }
    ]
};

// title from stepsConfig map to stepsDataConfig route path
export const stepsDataConfig = {
    [MAIN]: {
        search: "30+ buyback vendors",
        compare: "prices and seller feedback",
        sell: "for the most money"
    },
    [BUY]: {
        search: "25+ booksellers",
        compare: "prices and buyer feedback",
        save: "up to 70% on textbooks"
    },
    [RENT]: {
        search: "20+ rental vendors",
        compare: "prices and user feedback",
        save: "up to 70% on textbooks"
    }
};

export const stepsSeparatorConfig = {
    1: true,
    2: true,
    3: false
};
