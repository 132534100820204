import {
    SUCCESS_FETCH_CATEGORIES,
    SUCCESS_FETCH_POSTS,
    ERROR_FETCH_CATEGORIES,
    ERROR_FETCH_POSTS,
    SET_ALL_VENDORS
} from "./actionTypes";

export const initState = {
    isCategoriesLoading: true,
    isPostsLoading: true,
    isBooksLoading: false,
    categories: [],
    posts: [],
    booksError: null,
    postsError: null,
    categoriesError: null
};

export const setLazyInitState = ({ router }) => ({
    ...initState,
    get basePath() {
        return router.asPath;
    }
});

export const reducer = (state, { type, payload }) => {
    switch (type) {
        case SUCCESS_FETCH_POSTS:
            return { ...state, posts: [...payload], isPostsLoading: false };
        case ERROR_FETCH_POSTS:
            return { ...state, isPostsLoading: false, postsError: payload };

        case SUCCESS_FETCH_CATEGORIES:
            return {
                ...state,
                categories: [...payload],
                isCategoriesLoading: false
            };
        case ERROR_FETCH_CATEGORIES:
            return { ...state, isCategoriesLoading: false, categoriesError: payload };

        case SET_ALL_VENDORS:
            return { ...state, vendors: payload };

        default:
            return { ...state };
    }
};
