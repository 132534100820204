import { CUSTOM_LOGO_VENDORS } from "common/constants/vendors";

import { BUBBLES_NUMBER, CONSTANT_VENDORS, NORMALIZED_VENDORS_SLUGS } from "../constants";

const randomSortArray = (notSortedArray) => {
    const sortedArray = [...notSortedArray];

    for (let i = sortedArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = sortedArray[i];
        sortedArray[i] = sortedArray[j];
        sortedArray[j] = temp;
    }

    return sortedArray;
};

export const getRandomVendors = (allVendors, type) => {
    if (CONSTANT_VENDORS[type]) {
        const normalizedVendors = {};
        let vendors = {};
        let preSetVendors = {};

        allVendors.forEach((vendor) => {
            const slug = NORMALIZED_VENDORS_SLUGS[vendor.slug] || vendor.slug;
            normalizedVendors[slug] = vendor;
        });

        Object.keys(normalizedVendors).forEach((slug) => {
            if (CONSTANT_VENDORS[type][slug]) {
                preSetVendors[slug] = normalizedVendors[slug];
            } else {
                vendors[slug] = normalizedVendors[slug];
            }
        });

        preSetVendors = Object.values(preSetVendors);
        vendors = Object.values(vendors);
        vendors = randomSortArray(vendors);
        vendors = [...vendors.splice(0, BUBBLES_NUMBER - preSetVendors.length), ...preSetVendors];
        vendors = randomSortArray(vendors.slice(0, BUBBLES_NUMBER));
        vendors = vendors.map((vendor) => {
            if (CUSTOM_LOGO_VENDORS[vendor.slug]) {
                return { ...vendor, customLogo: true };
            }

            return vendor;
        });

        return vendors;
    } else {
        return allVendors.splice(0, BUBBLES_NUMBER);
    }
};
