import {
    VENDOR_ICON,
    VENDOR_LOGO,
    DEFAULT_VENDOR_ICON,
    DEFAULT_VENDOR_LOGO,
    VENDOR_CUSTOM_LOGO,
    DEFAULT_VENDOR_CUSTOM_LOGO
} from "components/VendorAsset/constants";

export const defaultVendorAsset = {
    [VENDOR_ICON]: DEFAULT_VENDOR_ICON,
    [VENDOR_LOGO]: DEFAULT_VENDOR_LOGO,
    [VENDOR_CUSTOM_LOGO]: DEFAULT_VENDOR_CUSTOM_LOGO
};

export const getImageSource = (vendor) => ({
    [VENDOR_ICON]: vendor.iconPath,
    [VENDOR_LOGO]: vendor.logoPath,
    [VENDOR_CUSTOM_LOGO]: vendor.customLogoPath
});
