import React, { memo } from "react";
import PropTypes from "prop-types";
import { cx } from "@linaria/core";
import * as S from "./Slider.styled";
const Slider = ({
  settings,
  children,
  className,
  ...restProps
}) => <S.Slider className={className} renderIndicator={(onClickHandler, isSelected, index, label) => <li className={cx("dot", isSelected && "selected")} onClick={onClickHandler} onKeyDown={onClickHandler} value={index} key={index} tabIndex={0} aria-label={`${label} ${index + 1}`}>
                {index + 1}
            </li>} {...settings} {...restProps}>
        {children}
    </S.Slider>;
Slider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object,
  className: PropTypes.string
};
Slider.defaultProps = {
  settings: {
    showArrows: true,
    showStatus: false,
    infiniteLoop: true,
    useKeyboardArrows: false,
    autoPlay: false,
    stopOnHover: true,
    swipeable: true,
    dynamicHeight: false,
    emulateTouch: false,
    autoFocus: false,
    interval: 5000,
    showThumbs: false
  },
  className: ""
};
export default memo(Slider);