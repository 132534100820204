import React, { memo, useCallback, useEffect, useState } from "react";
import Image from "next/image";
import PropTypes from "prop-types";
import { IMAGE_BLUR_PLACEHOLDER } from "common/utils";
import { VENDOR_LOGO, VENDOR_ICON, VENDOR_CUSTOM_LOGO } from "./constants";
import { defaultVendorAsset, getImageSource } from "./utils";
import * as S from "./VendorAsset.styled";
const VendorAsset = ({
  vendor,
  type,
  wrapper: WrapperComponent,
  imageProps
}) => {
  const [isAssetError, setAssetError] = useState(false);
  const handleAssetError = useCallback(() => setAssetError(prevState => !prevState), []);
  useEffect(() => {
    setAssetError(false);
  }, [vendor]);
  return (
    /**
     * in some cases we need isError in styles, so different implementations
     * between this and book asset
     **/
    <WrapperComponent isError={isAssetError}>
            <Image src={isAssetError ? defaultVendorAsset[type] : getImageSource(vendor)[type]} alt={`${vendor.name} ${type}`} aria-label={`${vendor.name} ${type}`} blurDataURL={IMAGE_BLUR_PLACEHOLDER} placeholder="blur" layout="fill" loading="lazy" objectFit="contain" onError={handleAssetError} {...imageProps} />
        </WrapperComponent>
  );
};
VendorAsset.propTypes = {
  vendor: PropTypes.object.isRequired,
  wrapper: PropTypes.elementType,
  type: PropTypes.oneOf([VENDOR_ICON, VENDOR_LOGO, VENDOR_CUSTOM_LOGO]),
  imageProps: PropTypes.object
};
VendorAsset.defaultProps = {
  wrapper: S.VendorIconAssetWrapper,
  type: VENDOR_ICON,
  imageProps: {}
};
export default memo(VendorAsset);