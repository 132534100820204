import React, { memo } from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import BookAsset from "components/BookAsset";
import * as S from "./BookPreviewCard.styled";
const BookPreviewCard = ({
  book,
  price,
  currency,
  urls,
  styles
}) => {
  const {
    isbn
  } = book;
  return <S.BookPreviewContainer style={styles}>
            <S.BookPreviewContentWrapper>
                <S.BookContent>
                    <BookAsset book={book} wrapper={S.BookPreviewContent} imageProps={{
          loading: "lazy",
          objectFit: "cover",
          priority: false,
          alt: `bookPreview_${isbn}}`,
          "aria-label": `bookPreview_${isbn}}`
        }} />
                    <S.BookPreviewInfo>
                        <S.BookPreviewTitleContainer>
                            <S.BookPreviewTitle>ISBN: {isbn}</S.BookPreviewTitle>
                        </S.BookPreviewTitleContainer>
                        <S.BookPreviewPriceSection currency={currency}>
                            {price}
                        </S.BookPreviewPriceSection>
                    </S.BookPreviewInfo>
                </S.BookContent>
                <S.BookPreviewActionsContainer>
                    <Link prefetch={false} href={urls.sell} passHref aria-label={`Sell book isbn-${isbn}`}>
                        <S.BookPreviewSellLink>SELL</S.BookPreviewSellLink>
                    </Link>
                    <Link prefetch={false} href={urls.buy} passHref aria-label={`Buy book isbn-${isbn}`}>
                        <S.BookPreviewBuyLink>BUY</S.BookPreviewBuyLink>
                    </Link>
                </S.BookPreviewActionsContainer>
            </S.BookPreviewContentWrapper>
        </S.BookPreviewContainer>;
};
BookPreviewCard.propTypes = {
  book: PropTypes.object.isRequired,
  price: PropTypes.string,
  currency: PropTypes.string,
  urls: PropTypes.shape({
    sell: PropTypes.string,
    buy: PropTypes.string
  }),
  styles: PropTypes.object
};
BookPreviewCard.defaultProps = {
  price: null,
  currency: "$",
  urls: {
    sell: "",
    buy: ""
  },
  styles: {}
};
export default memo(BookPreviewCard);