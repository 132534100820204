import { BUY_STATE, RENT_STATE, SELL_STATE } from "common/constants/searchTypes";

export const BUBBLES_NUMBER = 9;

//Based on figma design
export const DATA_CONFIG = [
    { x: 20, y: 74, r: 25.5, xTablet: 16, yTablet: 79.2, rTablet: 26.2, id: 1 },
    { x: 47, y: 76, r: 22.2, xTablet: 44, yTablet: 81.2, rTablet: 22.8, id: 2 },
    { x: 70.5, y: 68, r: 30.9, xTablet: 68, yTablet: 74.2, rTablet: 31.6, id: 3 },
    { x: 26, y: 46, r: 23.5, xTablet: 21, yTablet: 55.2, rTablet: 24.15, id: 4 },
    { x: 51, y: 44, r: 28.2, xTablet: 47, yTablet: 54.2, rTablet: 28.9, id: 5 },
    { x: 4, y: 33, r: 22.9, xTablet: 0.6, yTablet: 41, rTablet: 23.5, id: 6 },
    { x: 25, y: 19, r: 22.9, xTablet: 24, yTablet: 33, rTablet: 23.5, id: 7 },
    { x: 48, y: 8, r: 29.5, xTablet: 48, yTablet: 23.2, rTablet: 30.3, id: 8 },
    { x: 77, y: 26, r: 26, xTablet: 75, yTablet: 42, rTablet: 26.5, id: 9 }
];

export const NORMALIZED_VENDORS_SLUGS = {
    "abe-books": "abebooks",
    "amazon-ca": "amazon",
    "amazon-com": "amazon",
    "bigger-books": "biggerbooks",
    "bookbyte-com": "bookbyte",
    "book-depository": "bookdepository",
    "campus-book-rentals": "campusbookrentals",
    "ecampus-com": "ecampus",
    "ecampus-com-marketplace": "ecampus",
    "textbooks-com": "textbookscom",
    "textbooks-com-marketplace": "textbookscom",
    "buy-valor": "valore",
    "world-of-books-sell-your-books": "wob"
};

export const CONSTANT_VENDORS = {
    [SELL_STATE]: {
        sellbackyourbook: true,
        textbookrush: true,
        booksrun: true,
        valore: true
    },
    [BUY_STATE]: {
        amazon: true,
        ebay: true,
        abebooks: true,
        chegg: true
    },
    [RENT_STATE]: {
        booksrun: true,
        knetbooks: true,
        ecampus: true,
        biggerbooks: true,
        valore: true,
        chegg: true,
        efollet: true,
        textbookrush: true,
        vitalsource: true
    }
};
